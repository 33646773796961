import React, { useEffect, useMemo, useState } from 'react';

import useSocket from 'hooks/use-socket';
import { EventsMessagesEnum } from 'enums/events-messages.enum';
import { Buttons } from 'shared';

import 'features/student/styles.scss';
import { useQueryParams } from '../../../../hooks/use-query-params';
import Warning from '../../../../shared/warning';
import { WARNING_MESSAGES } from '../../../../constants/warning-messages';

type Props = {
  onJoinedSession: (joinedSession: boolean) => void;
  onChangeClassCode: (classCode: string) => void;
};

const StudentForm: React.FC<Props> = ({ onJoinedSession, onChangeClassCode }) => {
  const query = useQueryParams();
  const socket = useSocket();
  const queryClassCode = useMemo(() => query.get('classCode'), [query]);

  const [name, setName] = useState<string>('');
  const [classCode, setClassCode] = useState<string>('');
  const [sessionIsStarted, setSessionIsStarted] = useState<boolean>(true);
  const [classCodeExist, setClassCodeExist] = useState<boolean>(true);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (queryClassCode) {
      setClassCode(queryClassCode);
    }
  }, [socket, queryClassCode]);

  useEffect(() => {
    socket?.emit(EventsMessagesEnum.ClassCodeCheck, {
      classCode: classCode,
    });
  }, [classCode]);

  useEffect(() => {
    const classCodeCheckListener = (data: { classCodeExists: boolean; sessionExists: boolean }) => {
      if (formSubmitted && !queryClassCode && (!data.classCodeExists || !data.sessionExists)) {
        setWarningMessage(WARNING_MESSAGES.invalidClassCode);
        setClassCodeExist(false);
        return;
      }

      if (queryClassCode && !data.classCodeExists) {
        setWarningMessage(WARNING_MESSAGES.classCodeDoesNotExists);
        setClassCodeExist(false);
        return;
      }

      if (queryClassCode && !data.sessionExists) {
        setWarningMessage(WARNING_MESSAGES.sessionNotStarted);
        setSessionIsStarted(false);
        return;
      }
    };
    socket?.on(EventsMessagesEnum.ClassCodeChecked, classCodeCheckListener);

    return () => {
      socket?.off(EventsMessagesEnum.ClassCodeChecked, classCodeCheckListener);
    };
  }, [socket, queryClassCode, onJoinedSession]);

  useEffect(() => {
    const sessionEndedListener = (data: { classCode: string }) => {
      if (queryClassCode === data?.classCode) {
        setSessionIsStarted(false);
        setWarningMessage(WARNING_MESSAGES.sessionNotStarted);
        onJoinedSession(false);
      }
    };
    socket?.on(EventsMessagesEnum.SessionEnded, sessionEndedListener);

    return () => {
      socket?.off(EventsMessagesEnum.SessionEnded, sessionEndedListener);
    };
  }, [socket, queryClassCode]);

  useEffect(() => {
    const roomCreatedListener = (data: { classCode: string }) => {
      if (queryClassCode === data.classCode) {
        setWarningMessage('');
        setSessionIsStarted(true);
      }
    };
    socket?.on(EventsMessagesEnum.RoomCreated, roomCreatedListener);

    return () => {
      socket?.off(EventsMessagesEnum.RoomCreated, roomCreatedListener);
    };
  }, [socket, queryClassCode]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!socket || !socket.connected) {
      return;
    }

    if (!name) {
      setWarningMessage(WARNING_MESSAGES.invalidName);
      return;
    }

    if (!classCode) {
      setWarningMessage(WARNING_MESSAGES.invalidClassCode);
      return;
    }

    socket.emit(EventsMessagesEnum.ClassCodeCheck, {
      classCode: classCode,
    });

    socket?.emit(EventsMessagesEnum.JoinStudentToRoom, {
      name,
      classCode: classCode,
    });

    setName('');
    setClassCode('');
    onChangeClassCode(classCode);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="studentsWrap">
        {sessionIsStarted && queryClassCode && (
          <div className="actionsWrap">
            <input
              type="text"
              placeholder="YOUR NAME"
              className="input"
              value={name}
              minLength={2}
              onChange={e => setName(e.target.value)}
            />
            {!classCodeExist && (
              <input
                type="text"
                placeholder="CLASS CODE"
                className="input"
                value={classCode}
                onChange={e => setClassCode(e.target.value)}
              />
            )}
            <Buttons.Common type="submit" name="OK" stylesBtn="studentButton" />
          </div>
        )}

        {!queryClassCode && (
          <div className="actionsWrap">
            <input
              type="text"
              placeholder="YOUR NAME"
              className="input"
              value={name}
              minLength={2}
              onChange={e => setName(e.target.value)}
            />

            <input
              type="text"
              placeholder="CLASS CODE"
              className="input"
              value={classCode}
              onChange={e => setClassCode(e.target.value)}
            />
            <Buttons.Common type="submit" name="OK" stylesBtn="studentButton" />
          </div>
        )}

        {warningMessage && <Warning message={warningMessage} />}
      </div>
    </form>
  );
};

export default StudentForm;
