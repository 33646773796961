import React, { FC, useCallback, useContext } from 'react';
import classNames from 'classnames';

import { ROUTES } from 'constants/routes';
import logo from 'assets/icons/logo-default.png';

import SideBar from './SideBar';

import './nav-bar.scss';
import { clearSessionData, getClassCode, removeToken } from '../../utils/local-storage';
import { WP_URL } from '../../constants/config';
import { EventsMessagesEnum } from '../../enums/events-messages.enum';
import useSocket from '../../hooks/use-socket';
import { userContext } from '../../context/user-context';

type Props = {
  active?: number | undefined;
};

const NavBar: FC<Props> = ({ active }) => {
  const socket = useSocket();
  const { update: updateUserContext } = useContext(userContext);

  const endSession = useCallback(() => {
    if (!socket || !socket.connected) return;

    socket.emit(EventsMessagesEnum.EndSession, {
      classCode: getClassCode(),
    });
  }, [socket]);

  const handleSessionEnd = useCallback(() => {
    endSession();
    clearSessionData();
  }, [endSession]);

  const handleLogout = useCallback(() => {
    removeToken();
    handleSessionEnd();
    updateUserContext(null);
  }, [handleSessionEnd, updateUserContext]);

  return (
    <div id="nav" className="nav-container">
      <div className="nav-wrap">
        <div className="nav-logoWrap">
          <a href={WP_URL}>
            <img src={logo} alt="logo" width={145} height={38} />
          </a>
        </div>
        <>
          <div className="routes">
            {ROUTES.map((route, index) => (
              <div
                key={route.name}
                className={classNames(active === index ? 'active' : 'item')}
                style={{ color: '000' }}
              >
                <a href={route.path}>{route.name}</a>
              </div>
            ))}
            <div className="item" style={{ color: '000' }} onClick={handleLogout}>
              <a href={`${WP_URL}/wp-login.php?action=logout`}>Log Out</a>
            </div>
          </div>
        </>
        <div className="sidebar">
          <SideBar onLogout={handleLogout} />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
