import React, { Fragment, useMemo, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import { Alerts, Buttons } from 'shared';
import { API_URL } from 'constants/config';
import { MembershipEnum } from 'enums/membership.enum';
import { getSessionId } from '../../utils/local-storage';

type Props = {
  show?: boolean;
  onConfirm: (cb?: unknown) => void;
  membershipType: MembershipEnum;
};

export const EndSessionAlert: React.FC<Props> = ({ show = false, onConfirm, membershipType }) => {
  const [showEndFreeSessionModal, setShowEndFreeSessionPopup] = useState(false);
  const sessionId = useMemo(() => getSessionId(), []);

  const loadFile = async () => {
    const res = await fetch(`${API_URL}/logs/session/${sessionId}`);

    if (res.ok) {
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.hidden = true;
      a.href = url;
      a.download = 'logs.csv';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } else {
      console.log('response', res);
    }
  };

  const handleConfirm = async () => {
    if (!membershipType || membershipType === MembershipEnum.Basic) {
      setShowEndFreeSessionPopup(true);
      return;
    }
    onConfirm();
    await loadFile();
  };

  return showEndFreeSessionModal ? (
    <Alerts.EndFreeSession show={true} onConfirm={onConfirm} />
  ) : (
    <SweetAlert
      show={show}
      title="Session Log"
      showCancel
      showCloseButton
      onConfirm={onConfirm}
      customButtons={
        <Fragment>
          <Buttons.Common size="md" name="No" stylesBtn="backButton" stylesWrap="wrapBtnsGroup" onClick={onConfirm} />
          <Buttons.Common
            size="md"
            name="Yes"
            stylesBtn="yesButton"
            stylesWrap="wrapBtnsGroup"
            onClick={handleConfirm}
          />
        </Fragment>
      }
    >
      <div>
        <p>Would you like to export a log of your session?</p>
      </div>
    </SweetAlert>
  );
};
