import React, { FC, useEffect, useState } from 'react';

import { EventsMessagesEnum } from 'enums/events-messages.enum';
import { Footer, NavBar } from 'shared';
import useSocket from 'hooks/use-socket';
import { clearSessionData, getClassCode, getStudentId } from 'utils/local-storage';
import { RoomParticipant } from 'types/room-participant';

import StudentClassRoom from './components/student-class-room';
import StudentForm from './components/student-form';

import '../teacher/styles.scss';

const Classroom: FC = () => {
  const [hasJoinedSession, setHasJoinedSession] = useState(false);
  const [assistanceRequestsCount, setAssistanceRequestsCount] = useState(0);

  const [classCode, setClassCode] = useState<string>(getClassCode() || '');
  const socket = useSocket();

  useEffect(() => {
    const localStorageCode = getClassCode();
    const studentId = getStudentId();

    if (!socket || !socket.connected || !localStorageCode || hasJoinedSession || !studentId) {
      return;
    }

    socket.emit(EventsMessagesEnum.JoinRoom, {
      classCode: localStorageCode,
      studentId: studentId,
    });
    setHasJoinedSession(true);
  }, [socket, socket?.connected, hasJoinedSession]);

  useEffect(() => {
    const onTabClose = (e: Event) => {
      e.preventDefault();

      socket?.close();
      clearSessionData();
    };
    window.addEventListener('beforeunload', onTabClose);

    return () => {
      window.removeEventListener('beforeunload', onTabClose);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket || !socket.connected) return;

    const joinedRoomListener = (data: RoomParticipant & { countNotResolvedAssistanceRequests: number }) => {
      const studentId = getStudentId();
      if (studentId === data?.studentId) {
        setAssistanceRequestsCount(data?.countNotResolvedAssistanceRequests);
        setHasJoinedSession(true);
      }
    };

    socket.on(EventsMessagesEnum.JoinedRoom, joinedRoomListener);

    return () => {
      socket.off(EventsMessagesEnum.JoinedRoom, joinedRoomListener);
    };
  }, [socket, socket?.connected]);

  return (
    <div>
      <div className="container">
        <NavBar active={3} />
        {!hasJoinedSession ? (
          <StudentForm
            onChangeClassCode={setClassCode}
            onJoinedSession={joinedSession => setHasJoinedSession(joinedSession)}
          />
        ) : (
          <StudentClassRoom
            initialAssistanceRequestsCount={assistanceRequestsCount}
            classCode={classCode}
            onLeaveSession={() => setHasJoinedSession(false)}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Classroom;
