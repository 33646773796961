export enum EventsMessagesEnum {
  Disconnect = 'disconnect',
  Connect = 'connect',
  Reconnect = 'reconnect',
  JoinRoom = 'join-room',
  JoinStudentToRoom = 'join-student-to-room',
  JoinedRoom = 'joined-room',
  RoomParticipants = 'room-participants',
  SessionEnded = 'session-ended',
  RequestAssistance = 'request-assistance',
  AssistanceRequestNumber = 'assistance-request-number',
  AssistanceRequests = 'assistance-requests',
  AnswerAssistanceRequest = 'answer-assistance-request',
  AssistanceRequestAnswered = 'assistance-request-answered',
  CancelAssistanceRequest = 'cancel-assistance-request',
  ClearAllAssistanceRequests = 'clear-all-assistance-requests',
  CreateTeachersRoom = 'create-teachers-room',
  RoomCreated = 'room-created',
  EndSession = 'end-session',
  LeaveRoom = 'leave-room',
  LeftRoom = 'left-room',
  QueueStatus = 'queue-status',
  QueueIsFull = 'queue-is-full',
  QueueIsNotFull = 'queue-is-not-full',
  InvalidToken = 'invalid-token',
  RoomWasNotCreated = 'room-was-not-created',
  ClassCodeCheck = 'class-code-check',
  ClassCodeChecked = 'class-code-checked',
}
