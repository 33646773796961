import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

import { EventsMessagesEnum } from 'enums/events-messages.enum';
import { UserType } from 'enums/user-type.enum';
import { RoomParticipant } from 'types/room-participant';
import { API_URL } from 'constants/config';
import { saveClassCode, saveStudentId } from '../utils/local-storage';

function useSetupSocket() {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [socketId, setSocketId] = useState<string | null>(null);

  useEffect(() => {
    if (!socket) {
      setSocket(
        io(API_URL, {
          secure: true,
          reconnection: true,
          rejectUnauthorized: false,
          closeOnBeforeunload: false,
          autoConnect: true,
          transports: ['websocket'],
        })
      );
    }
  }, [socket, setSocket]);

  useEffect(() => {
    if (socket) {
      socket.on(EventsMessagesEnum.Disconnect, () => {
        // eslint-disable-next-line no-console
        console.info('socket connection lost', `socket id ${socketId}`);
      });

      socket.on(EventsMessagesEnum.JoinedRoom, (data: RoomParticipant) => {
        if (data?.clientId === socket.id && data?.classCode) {
          saveClassCode(data.classCode);
          if (data.userType === UserType.Student) {
            saveStudentId(data?.studentId);
          }
        }
      });

      socket.on(EventsMessagesEnum.Connect, () => {
        // eslint-disable-next-line no-console
        console.info('socket connected', `socket id ${socket.id}`);
        setSocketId(socket.id);
      });

      socket.on(EventsMessagesEnum.Reconnect, () => {
        setSocketId(socket.id);
      });
    }
  }, [socket, socketId]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.close();
        setSocket(null);
      }
    };
  }, [socket, setSocket]);

  return socket;
}

export default useSetupSocket;
